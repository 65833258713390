import React from 'react';
import {
	EmailShareButton,
	FacebookShareButton,
	LinkedinShareButton,
	TwitterShareButton,
	WhatsappShareButton,
	RedditShareButton,
	EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  RedditIcon,
  TwitterIcon,
  WhatsappIcon,
} from 'react-share';

import './Share.scss';

const Share = ({ socialConfig }) => (
	<div className="post-social">
		<br />
		<div>---</div>
		<div>Please share this article:</div>
		<FacebookShareButton url={socialConfig.config.url} className="button is-outlined is-rounded facebook" >
			<span className="icon">
				<FacebookIcon size={44} round/>
			</span>
		</FacebookShareButton>
		<TwitterShareButton url={socialConfig.config.url} className="button is-outlined is-rounded twitter" title={socialConfig.config.title} >
			<span className="icon">
				<TwitterIcon size={44} round/>
			</span>
		</TwitterShareButton>
		<EmailShareButton url={socialConfig.config.url} className="button is-outlined is-rounded facebook" >
			<span className="icon">
				<EmailIcon size={44} round/>
			</span>
		</EmailShareButton>
		<LinkedinShareButton url={socialConfig.config.url} className="button is-outlined is-rounded linkedin" title={socialConfig.config.title} >
			<span className="icon">
				<LinkedinIcon size={44} round/>
			</span>
		</LinkedinShareButton>
		<RedditShareButton url={socialConfig.config.url} className="button is-outlined is-rounded reddit" title={socialConfig.config.title} >
			<span className="icon">
				<RedditIcon size={44} round/>
			</span>
		</RedditShareButton>
		<WhatsappShareButton url={socialConfig.config.url} className="button is-outlined is-rounded whatsapp" title={socialConfig.config.title} >
			<span className="icon">
				<WhatsappIcon size={44} round/>
			</span>
		</WhatsappShareButton>
	</div>
);

export default Share;